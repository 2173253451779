<template>
  <v-card
    class="elevation-0 mx-auto"
    max-width="1024"
  >
    <HeaderCard>
      <v-icon
        class="mr-1"
        size="medium"
      >
        mdi-arrow-up-bold-circle-outline
      </v-icon>
      <b>Atualizações e Melhorias</b>
      <v-spacer />

      <v-btn
        v-if="isAdmin"
        x-small
        outlined
        color="white"
        @click="handleNewUpdate"
      >
        <v-icon small>
          mdi-plus
        </v-icon>
        Cadastrar
      </v-btn>
    </HeaderCard>

    <v-card-text class="pl-0 pt-0">
      <v-timeline dense>
        <v-timeline-item
          v-for="row in rows"
          :key="row.id"
          color="primary"
          fill-dot
          :icon="row.type === 'Melhoria' ? 'mdi-checkbox-marked-circle-plus-outline' : 'mdi-thumb-up-outline'"
        >
          <UpdateListData
            :session="session"
            :row="row"
          />
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { getToken, getSession } from '@/utils'
import HeaderCard from '@/components/HeaderCard'
import UpdateListData from './UpdateListData'
import { mapState } from 'vuex'

import {
  STATUS_LOADING,
  ROUTE_UPDATES_NEW
} from '@/constants'

export default {
  components: {
    HeaderCard,
    UpdateListData
  },

  data () {
    return {
      session: {}
    }
  },

  mounted () {
    this.loadSession()
    this.loadUpdates()
  },

  computed: {
    ...mapState({
      rows: ({ updates }) => updates.rows,
      isLoading: ({ updates }) => updates.status === STATUS_LOADING
    }),

    isAdmin () {
      return this.session?.administrador
    },

    headers () {
      return [
        { text: 'Data/Hora', align: 'center', sortable: true, value: 'updateDate' },
        { text: 'Título', align: 'start', sortable: true, value: 'title' },
        { text: 'Tipo', align: 'start', sortable: true, value: 'type' },
        { text: '', align: 'start', sortable: false, value: '' },
        { text: '', align: 'start', sortable: false, value: '' }
      ]
    }
  },

  methods: {
    loadSession () {
      getToken().then((token) => (
        getSession().then((session) => (
          Object.assign(this, { session })
        ))
      ))
    },

    loadUpdates () {
      this.$store.dispatch('loadUpdates')
    },

    handleNewUpdate () {
      this.$router.push({ name: ROUTE_UPDATES_NEW })
    }

  }
}

</script>
