<template>
  <v-card
    color="primary"
    outlined
  >
    <v-card-title
      class="text-h6 py-1 text-truncate primary--text white"
    >
      {{ row.title }}
    </v-card-title>

    <v-divider />

    <v-card-text class="white pb-0">
      <strong class="primary--text">
        {{ row.type }} em {{ date }}
      </strong>

      <p
        class="ck ck-content ck-blurred py-2"
        lang="pt-br"
        dir="ltr"
        role="textbox"
        v-html="row.description"
      />

      <v-divider v-if="isAdmin" />

      <v-card-actions v-if="isAdmin">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              outlined
              small
              color="primary"
              class="mr-3"
              @click="editUpdate"
            >
              <v-icon small>
                mdi-pencil
              </v-icon> Editar
            </v-btn>
          </template>
          <span>Alterar Atualização</span>
        </v-tooltip>

        <v-dialog
          v-model="dialog"
          width="500"
          v-if="isAdmin"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              small
              color="red"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-delete
              </v-icon> Excluir
            </v-btn>
          </template>

          <v-card dense>
            <v-card-title class="text-center ">
              Tem certeza que deseja Excluir?
            </v-card-title>

            <v-divider />

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="red"
                dark
                text
                @click="removeUpdate"
              >
                Sim, quero Excluir!
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment-timezone'
import { ROUTE_UPDATES_EDIT } from '@/constants'

export default {
  props: {
    session: {},
    row: {}
  },

  data () {
    return {
      dialog: false
    }
  },

  computed: {
    date () {
      const updateDate = this.row.updateDate.split('T')[0]
      return moment(updateDate).format('DD/MM/YYYY')
    },

    isAdmin () {
      return this.session?.administrador
    }

  },

  methods: {
    removeUpdate () {
      this.$store.dispatch('removeUpdate', this.row.id)
      this.dialog = false
    },
    editUpdate () {
      this.$router.push({ name: ROUTE_UPDATES_EDIT, params: { id: this.row.id } })
    }
  }
}
</script>
